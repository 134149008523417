import React from 'react';
import Header from '../components/header';
import Footers from '../components/footers';

const TermsPolicy = () => {
    return (
        <div className='h-screen flex flex-col'>
            <Header isHome={false} className="sticky top-0 z-50" />
            <div className='dark:bg-black flex-1'>
                <div className='flex-1 flex items-center justify-center py-10 flex-col'>
                    <p className='text-center font-black text-4xl text-black dark:text-white'>Terms and Conditions</p>
                    <div className='w-4/5 py-20'>
                        <h1>Terms and Conditions</h1>
                        <p>Welcome to Courses.Vyeron.com!</p>
                        
                        <p>These terms and conditions outline the rules and regulations for the use of Vyeron.com's Website, located at <a href="https://www.courses.vyeron.com/">https://www.courses.vyeron.com/</a>.</p>

                        <p>By accessing this website we assume you accept these terms and conditions. Do not continue to use Courses.Vyeron.com if you do not agree to take all of the terms and conditions stated on this page.</p>

                        <p>The following terminology applies to these Terms and Conditions, Privacy Statement, and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company’s terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance, and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services, in accordance with and subject to, prevailing law of Netherlands. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.</p>

                        <h2>Cookies</h2>
                        <p>We employ the use of cookies. By accessing Courses.Vyeron.com, you agreed to use cookies in agreement with Vyeron.com's Privacy Policy.</p>

                        <p>Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.</p>

                        <h2>License</h2>
                        <p>Unless otherwise stated, Vyeron.com and/or its licensors own the intellectual property rights for all material on Courses.Vyeron.com. All intellectual property rights are reserved. You may access this from Courses.Vyeron.com for your own personal use subjected to restrictions set in these terms and conditions.</p>

                        <h3>You must not:</h3>
                        <ul>
                            <li>Republish material from Courses.Vyeron.com</li>
                            <li>Sell, rent, or sub-license material from Courses.Vyeron.com</li>
                            <li>Reproduce, duplicate, or copy material from Courses.Vyeron.com</li>
                            <li>Redistribute content from Courses.Vyeron.com</li>
                        </ul>

                        <p>This Agreement shall begin on the date hereof. Our Terms and Conditions were created with the help of the Terms and Conditions Generator.</p>

                        <p>Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. Vyeron.com does not filter, edit, publish, or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of Vyeron.com, its agents, and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, Vyeron.com shall not be liable for the Comments or for any liability, damages, or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.</p>

                        <p>Vyeron.com reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive, or causes a breach of these Terms and Conditions.</p>

                        <h2>You warrant and represent that:</h2>
                        <ul>
                            <li>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</li>
                            <li>The Comments do not invade any intellectual property right, including without limitation copyright, patent, or trademark of any third party;</li>
                            <li>The Comments do not contain any defamatory, libelous, offensive, indecent, or otherwise unlawful material which is an invasion of privacy;</li>
                            <li>The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</li>
                        </ul>

                        <p>You hereby grant Vyeron.com a non-exclusive license to use, reproduce, edit, and authorize others to use, reproduce, and edit any of your Comments in any and all forms, formats, or media.</p>

                        <h2>Hyperlinking to our Content</h2>
                        <p>The following organizations may link to our Website without prior written approval:</p>
                        <ul>
                            <li>Government agencies;</li>
                            <li>Search engines;</li>
                            <li>News organizations;</li>
                            <li>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and</li>
                            <li>System-wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Website.</li>
                        </ul>

                        <p>These organizations may link to our home page, to publications, or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party’s site.</p>

                        <h2>Disclaimer</h2>
                        <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website. Nothing in this disclaimer will:</p>
                        <ul>
                            <li>limit or exclude our or your liability for death or personal injury;</li>
                            <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                            <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                            <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
                        </ul>

                        <p>As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</p>
                    </div>
                </div>
            </div>
            <Footers className="sticky bottom-0 z-50" />
        </div>
    );
};

export default TermsPolicy;
