import React from 'react';
import Header from '../components/header';
import Footers from '../components/footers';
import { Card } from 'flowbite-react';
import { IoIosTimer } from "react-icons/io";
import { BsSearch } from "react-icons/bs";
import { PiVideo } from "react-icons/pi";

const Features = () => {

    const cardsFeatures = [
        {
            id: 1,
            title: 'Topic Creation',
            description: 'Quickly input topics and subtopics using our easy-to-use interface',
        },
        {
            id: 2,
            title: 'Course Options Preferences',
            description: 'Choose between Theory + Image or Theory + Video for a personalized learning experience',
        },
        {
            id: 3,
            title: 'AI-Generated Content',
            description: 'Our advanced AI evaluates your inputs to produce detailed courses',
        },  
        {
            id: 4,
            title: 'Learning Styles and Preferences',
            description: 'Support different learning styles by focusing on images, videos, or textual content',
        },
        {
            id: 5,
            title: 'Personalized Curriculum',
            description: 'Receive a personalized curriculum crafted to match your preferences',
        },
        {
            id: 6,
            title: 'Real-time Course Preview',
            description: 'View a real-time preview of your generated course before finalizing',
        },
    ];

    const cardsWork = [
        {
            id: 1,
            title: 'Enter Course Topics',
            description: 'Begin creating your course by entering your desired topics and a list of subtopics',
        },
        {
            id: 2,
            title: 'Configure Preferences',
            description: 'Decide between Theory + Image or Theory + Video for a personalized learning path.',
        },
        {
            id: 3,
            title: 'AI Course Creation',
            description: 'Our AI will take your inputs and produce a personalized course',
        }
    ];

    const cardBenefit = [
        {
            id: 1,
            title: 'Instant course generation',
            description: 'Save hours and improve time efficiency with instant course generation',
            icon: <IoIosTimer className="text-xl max-md:text-lg  dark:text-white" />
        },
        {
            id: 2,
            title: 'AI-Enhanced Learning Materials',
            description: 'Ensure superior content quality with AI-based advice',
            icon: <BsSearch className="text-xl max-md:text-lg  dark:text-white" />
        },
        {
            id: 3,
            title: 'Interactive Learning Methods',
            description: 'Utilizing various media formats to maintain user engagement',
            icon: <PiVideo className="text-xl max-md:text-lg  dark:text-white" />
        }
    ];

    const cardsqa = [
        {
            id: 1,
            title: 'Q: How to create an account ?',
            description: 'A: To create an account, click on the “Sign Up” button, fill in your details, and follow the on-screen instructions to complete the registration process',
        },
        {
            id: 2,
            title: 'Q: Can I Login with social media?',
            description: 'A: Yes, you can register and login with social media by selecting the appropriate option during the sign-up or login process',
        },
        {
            id: 3,
            title: 'Q: Is Course.Vyeron.com free?',
            description: 'A: Yes, the website is absolutely free, and all services are available at no cost. You can build an unlimited number of courses without any charges',
        },  
        {
            id: 4,
            title: 'Q: How do I create a course ?',
            description: 'A: Begin by selecting a main topic. For enhanced clarity, consider adding several subtopics to break down the content into manageable sections',
        },
        {
            id: 5,
            title: 'Q: What type of topics can I use ?',
            description: 'A: You can use absolutely any type of topic you want to learn. It’s important to start the topic with a focus keyword to ensure clarity and relevance',
        },
        {
            id: 6,
            title: 'Q: Can I build without subtopics ?',
            description: 'A: Yes, you can build a course without subtopics. However, adding subtopics can help organize your content and make it easier for learners to follow',
        },
    ];

    const style = {
        "root": {
            "base": "max-w-sm flex rounded-none border border-black bg-white shadow-none dark:border-white dark:bg-black m-4",
            "children": "flex h-full flex-col justify-center gap-3 p-5",
            "horizontal": {
                "off": "flex-col",
                "on": "flex-col md:max-w-xl md:flex-row"
            },
            "href": "hover:bg-white dark:hover:bg-black"
        },
        "img": {
            "base": "",
            "horizontal": {
                "off": "rounded-none",
                "on": "h-96 w-full rounded-none object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
            }

        }
    }

    const styleTwo = {
        "root": {
            "base": "max-w-sm flex rounded-none  bg-white shadow-none dark:bg-black m-4",
            "children": "flex h-full flex-col justify-center gap-3 p-5",
            "horizontal": {
                "off": "flex-col",
                "on": "flex-col md:max-w-xl md:flex-row"
            },
            "href": "hover:bg-white dark:hover:bg-black"
        },
        "img": {
            "base": "",
            "horizontal": {
                "off": "rounded-none",
                "on": "h-96 w-full rounded-none object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
            }

        }
    }

    return (
        <div className='h-screen flex flex-col'>
            <Header isHome={false} className="sticky top-0 z-50" />
            <div className='dark:bg-black flex-1'>
                <div className='flex-1 flex flex-col items-center justify-center'>
                    <h1 className="text-6xl font-black mt-14 max-md:text-3xl dark:text-white">Features</h1>
                    <p className="text-center text-black mt-6 max-w-2xl font-medium max-md:text-xs dark:text-white">
                        Craft your courses Instantly
                    </p>
                    <div className='mt-16 flex flex-wrap items-center justify-center'>
                        {cardsFeatures.map((card) => (
                            <Card key={card.id} theme={style}>
                                <h5 className='text-xl font-black tracking-tight text-black dark:text-white'>
                                    {card.title}
                                </h5>
                                <p className='font-normal text-sm text-black dark:text-white'>{card.description}</p>
                            </Card>
                        ))}
                    </div>
                </div>
                <div className='flex-1 flex flex-col items-center justify-center'>
                    <h1 className="text-4xl font-black mt-14 max-md:text-2xl dark:text-white">Benefits</h1>
                    <div className='mt-16 flex flex-wrap items-center justify-center'>
                        {cardBenefit.map((card) => (
                            <Card key={card.id} theme={style}>
                                {card.icon}
                                <h5 className='text-xl font-black tracking-tight text-black dark:text-white'>
                                    {card.title}
                                </h5>
                                <p className='font-normal text-sm text-black dark:text-white'>{card.description}</p>
                            </Card>
                        ))}
                    </div>
                </div>
                <div className='flex-1 flex flex-col items-center justify-center'>
                    <h1 className="text-4xl font-black mt-14 max-md:text-2xl dark:text-white">How It Works</h1>
                    <div className='my-16 flex flex-wrap items-center justify-center'>
                        {cardsWork.map((card) => (
                            <Card key={card.id} theme={styleTwo}>
                                <p className='text-black dark:text-white'>{card.id}</p>
                                <h5 className='text-xl font-black tracking-tight text-black dark:text-white'>
                                    {card.title}
                                </h5>
                                <p className='font-normal text-sm text-black dark:text-white'>{card.description}</p>
                            </Card>
                        ))}
                    </div>
                </div>
                <div className='flex-1 flex flex-col items-center justify-center'>
                    <h1 className="text-4xl font-black mt-14 max-md:text-2xl dark:text-white">Q & A</h1>
                    <div className='mt-16 flex flex-wrap items-center justify-center'>
                        {cardsqa.map((card) => (
                            <Card key={card.id} theme={style}>
                                {card.icon}
                                <h5 className='text-xl font-black tracking-tight text-black dark:text-white'>
                                    {card.title}
                                </h5>
                                <p className='font-normal text-sm text-black dark:text-white'>{card.description}</p>
                            </Card>
                        ))}
                    </div>
                </div>
            </div>
            <Footers className="sticky bottom-0 z-50" />
        </div>
    );
};

export default Features;
