//BRAND
export const name = 'Vyeron.com';
export const company = 'Vyeron.com';
export const websiteURL = 'https://www.courses.vyeron.com';
export const serverURL = 'https://courses-vyeron-com.onrender.com';
export const logo = 'https://firebasestorage.googleapis.com/v0/b/courses-vyeron-com.appspot.com/o/logo512.png?alt=media&token=24db0b9b-c290-401d-ba74-0c8505e90f04';
export const razorpayEnabled = false;
export const paypalEnabled = false;
export const stripeEnabled = false;
export const paystackEnabled = false;
export const flutterwaveEnabled = false;

//PRICING :-

//FREE 
export const FreeType = 'Free Plan';
export const FreeCost = 0;
export const FreeTime = '';

//MONTHLY 
export const MonthType = 'Monthly Plan';
export const MonthCost = 0;
export const MonthTime = 'month';

//YEARLY 
export const YearType = 'Yearly Plan';
export const YearCost = 0;
export const YearTime = 'year';

//TESTIMONIAL
export const review = "Vyeron.com AI Course Generator revolutionizes course creation by effortlessly generating accurate and relevant topics, saving educators countless hours. This powerful tool empowers educators to create more engaging and impactful courses, making it an indispensable asset for those seeking efficiency in content creation.";
export const from = "Courses.Vyeron.com";
export const profession = 'Vyeron.com';
export const photoURL = 'https://play-lh.googleusercontent.com/sV_ffBmBJt_je4RZHnfaCfcnL-Hy6C14Iol7H5EMj9fzI2GDOonuojdn5t9p6n9IAX8j';

//PAYPAL
export const paypalPlanIdOne = "P-1EM732768S920784HMWKW3OA";
export const paypalPlanIdTwo = "P-8T744865W27080359MWOCE5Q";

//RAZORPAY
export const razorpayKeyId = "rzp_test_uqALjZHyTyuiOm";
export const razorpayPlanIdOne = "plan_NMvvtDfznbRp6V";
export const razorpayPlanIdTwo = "plan_NMRc9HARBQRLWA";

//STRIPE
export const stripePlanIdOne = "price_1OTo7CSDXmLtVnVeaHIHxqCj";
export const stripePlanIdTwo = "price_1OTo7eSDXmLtVnVeBbn82U5B";

//PAYSTACK
export const paystackPlanIdOne = "PLN_ouqmm8eo6i2k9k8";
export const paystackPlanIdTwo = "PLN_1v1xqb8io9t5lis";
export const amountInZarOne = '170';
export const amountInZarTwo = '1871';

//FLUTTERWAVE
export const flutterwavePlanIdOne = "67960";
export const flutterwavePlanIdTwo = "67961";
export const flutterwavePublicKey = "FLWPUBK_TEST-6ee1faf6460ea587f510a024ac4c2b23-X";

//SOCIAL SIGNIN //
export const googleClientId = "265744145927-53l591cb722ffgv84q6q8odjsmsaoj1l.apps.googleusercontent.com";
export const facebookClientId = "8065327730213158";
